import axios from "axios";
import { getApiUrl } from "../functions/Commons";

export default (token: string, startDate: string, endDate: string) => {
  let dispatch = {};
  const url = `${getApiUrl()}/user/loginHistory?startDate=${startDate}&endDate=${endDate}`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      const dispatch = {
        loginHistory: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
