import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import fetchHospitalListForRefer from "../../actions/Refers/fetchHospitalListForRefer.action";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DatePicker from "@mui/lab/DatePicker";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListboxComponent from "../Utility/ListBoxComponents";
import "../../styles/refers/ReferNavbar.scss";
import { RootState } from "../../reducers";
import { shortHospName } from "../../functions/FuncPerjer";
import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete, Box } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import { IReferral, ReferralType } from "../../types/refers";
import fetchRefers from "../../actions/Refers/fetchRefers";
import Swal from "sweetalert2";
import MoneyIcon from "@mui/icons-material/Money";

enum ReferPoint {
  ALL = "ALL",
  ER = "ER",
  OPD = "OPD",
  IPD = "IPD",
}

interface IHospital {
  hospCode: string;
  hospName: string;
}

interface IReferNavbar {
  referType: ReferralType;
  isRefresh?: boolean;
  onUpdateReferList: (referList: IReferral[]) => void;
}

interface ISuperAdmin {
  status: boolean;
  selectedHospital?: IHospital;
}

interface IFilter {
  hospCode?: string;
  referPoint?: ReferPoint;
}

export default function ReferNavbar({
  referType,
  isRefresh = false,
  onUpdateReferList,
}: IReferNavbar) {
  const { t } = useTranslation("referral");

  const history = useHistory();

  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const {
    permissionRules,
    FetchingStatus,
    idToken,
    hospitalList,
    emrData,
    loginData,
  } = appData;
  const { referData }: { referData: IReferral[] } = emrData;
  const { hospCode } = loginData;

  const storedViewMode = localStorage.getItem("viewMode") as "recent" | "today";
  const defaultSelectedDate =
    storedViewMode === "today" ? dayjs().toDate() : null;

  const urlParams = new URLSearchParams(history.location.search);
  const dateParam = urlParams.get("date");
  const selectedDateFromUrl = dateParam ? new Date(dateParam) : null;

  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();
  const [selectedDate, setSelectedDate] = useState<null | Date>(
    selectedDateFromUrl || defaultSelectedDate
  );
  const [viewMode, setViewMode] = useState<"recent" | "today">(
    storedViewMode || "recent"
  );
  const [superAdmin, setSuperAdmin] = useState<ISuperAdmin>();
  const [searchText, setSearchText] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hospitalOptions, setHospitalOptions] = useState<IHospital[]>([]);
  const [filters, setFilters] = useState<null | IFilter>(null);

  const dateChangedByButton = useRef(false);
  const calendarOpenFrom = useRef<null | string>(null);

  const getDistinctHospitals = (
    referrals: IReferral[],
    referType: ReferralType
  ): IHospital[] => {
    const hospitalMapper = (referral: IReferral) => {
      const hospCode =
        referType === ReferralType.REFER_IN
          ? referral.data.fromHospCode
          : referral.data.toHospCode;
      const hospName =
        referType === ReferralType.REFER_IN
          ? referral.fromHospName || ""
          : referral.toHospName || "";

      return { hospCode, hospName };
    };

    const hospitals = referrals
      .map(hospitalMapper)
      .filter((hospital) => hospital.hospCode)
      .reduce<{ hospCode: string; hospName: string }[]>((acc, curr) => {
        if (!acc.find((item) => item.hospCode === curr.hospCode)) {
          acc.push(curr);
        }
        return acc;
      }, []);

    return hospitals;
  };

  const showErrorAlert = (message: string) => {
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: message,
      confirmButtonColor: "#3085d6",
    });
  };

  const getSearchQuery = () => {
    if (!searchText) return {};

    const nSearch = Number(searchText);

    if (isNaN(nSearch)) {
      return { name: searchText, limit: 100 };
    }

    if (searchText.length === 13) {
      return { cid: searchText, limit: 100 };
    }

    throw new Error("ไม่สามารถค้นหาข้อมูลได้ กรุณาตรวจสอบคำค้นหาใหม่อีกครั้ง");
  };

  const getHospCodes = () => {
    let fromHospCode = "";
    let toHospCode = "";

    if (referType === ReferralType.REFER_IN) {
      if (superAdmin?.status === true) {
        toHospCode = superAdmin?.selectedHospital?.hospCode || "";
      } else {
        toHospCode = hospCode;
      }
    } else {
      if (superAdmin?.status === true) {
        fromHospCode = superAdmin?.selectedHospital?.hospCode || "";
      } else {
        fromHospCode = hospCode;
      }
    }

    return { fromHospCode, toHospCode };
  };

  const handleFetchRefers = (e?: React.SyntheticEvent) => {
    try {
      e?.preventDefault();

      const searchQuery = getSearchQuery();
      const hospCodes = getHospCodes();

      const body = {
        ...searchQuery,
        ...hospCodes,
        idToken,
        startDate: selectedDate,
        endDate: selectedDate,
      };
      dispatch(fetchRefers(body));

      if (selectedDate) {
        history.push({
          search: `?date=${selectedDate.toISOString().split("T")[0]}`,
        });
      } else {
        history.push(
          `/refer-${referType === ReferralType.REFER_IN ? "in" : "out"}`
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        showErrorAlert(error.message);
      } else {
        showErrorAlert("เกิดข้อผิดพลาดในการค้นหาข้อมูล");
      }
    }
  };

  const handleToggleViewMode = () => {
    const mode = viewMode === "recent" ? "today" : "recent";

    localStorage.setItem("viewMode", mode);
    setViewMode(mode);
    setFilters(null);

    if (mode === "recent") {
      setSelectedDate(null);
    } else {
      const today = dayjs();
      setSelectedDate(today.toDate());
    }
  };

  const onNextDayChange = () => {
    const today = dayjs();
    const next = selectedDate ? dayjs(selectedDate).add(1, "day") : today;

    if (!next.isAfter(today)) {
      setFilters(null);
      setSelectedDate(next.toDate());
    }
  };

  const onPreviousDayChange = () => {
    if (!selectedDate) {
      setSelectedDate(dayjs().toDate());
      return;
    }

    setFilters(null);
    setSelectedDate(dayjs(selectedDate).subtract(1, "day").toDate());
  };

  const filterReferData = (referData: IReferral[]) => {
    const filteredReferData = referData.filter((refer) => {
      let isMatch = true;
      if (filters?.hospCode && filters?.hospCode !== "ALL") {
        isMatch = isMatch && refer.data.fromHospCode === filters?.hospCode;
      }
      if (filters?.referPoint && filters?.referPoint !== ReferPoint.ALL) {
        isMatch = isMatch && refer.data.referPoint === filters?.referPoint;
      }
      return isMatch;
    });
    return filteredReferData;
  };

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => e.preventDefault());
    return () => {
      window.removeEventListener("beforeunload", (e) => e.preventDefault());
    };
  }, []);

  useEffect(() => {
    if (referData?.length > 0) {
      const hospitals = getDistinctHospitals(referData, referType);
      setHospitalOptions(hospitals);

      if (filters) {
        const filteredReferData = filterReferData(referData);
        onUpdateReferList(filteredReferData);
      } else {
        onUpdateReferList(referData);
      }
    }
  }, [referData, filters]);

  useEffect(() => {
    if (referData?.length > 0 && filters) {
      const filteredReferData = filterReferData(referData);
      onUpdateReferList(filteredReferData);
    }
  }, [filters]);

  useEffect(() => {
    isRefresh && handleFetchRefers();
  }, [isRefresh]);

  useEffect(() => {
    handleFetchRefers();
  }, [selectedDate]);

  useEffect(() => {
    if (ability) {
      if (
        ability.can(
          ServicePermissionEvent.READ,
          ServicePermissionService.ANY_HOSPITAL_REFER
        )
      ) {
        dispatch(
          fetchHospitalListForRefer({
            idToken: idToken,
            limit: 100,
          })
        );
        setSuperAdmin({ status: true });
      }
    }
  }, [ability]);

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  const hospitalFilter = () => {
    return (
      <FormControl className="flex-auto w-full sm:w-36 md:w-80 lg:w-36 xl:w-80">
        <Select
          fullWidth
          className="bg-transparent"
          displayEmpty
          value={filters?.hospCode || "ALL"}
          onChange={(e) => setFilters({ ...filters, hospCode: e.target.value })}
        >
          <MenuItem value="ALL">{t("refer.hospital")}</MenuItem>
          {hospitalOptions.map((hospital) => {
            const hospName = hospital.hospName || hospital.hospCode;
            const maxLen = 30;
            const truncated =
              hospName.length > maxLen
                ? `${hospName.slice(0, maxLen)}...`
                : hospName;
            return (
              <MenuItem key={hospital.hospCode} value={hospital.hospCode}>
                {truncated}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const referPointFilter = () => {
    return (
      <FormControl className="flex-auto w-full sm:w-36 md:w-48 lg:w-36 xl:w-48">
        <Select
          displayEmpty
          className="bg-transparent"
          value={filters?.referPoint || ReferPoint.ALL}
          onChange={(e) =>
            setFilters({ ...filters, referPoint: e.target.value as ReferPoint })
          }
        >
          <MenuItem value={ReferPoint.ALL}>{t("refer.referPoint")}</MenuItem>
          <MenuItem value={ReferPoint.ER}>- ER -</MenuItem>
          <MenuItem value={ReferPoint.OPD}>- OPD -</MenuItem>
          <MenuItem value={ReferPoint.IPD}>- IPD -</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const searchBar = () => {
    return (
      <form
        onSubmit={(e) => {
          setFilters(null);
          setSelectedDate(null);
          handleFetchRefers(e);
        }}
      >
        <TextField
          className="flex-auto w-full sm:w-80 md:w-96 lg:w-80 xl:w-96 bg-gray-100 rounded-full"
          name="searchBar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={t("refer.searchBar")}
          variant="outlined"
          sx={{
            "& input:-webkit-autofill": {
              px: 1,
              py: 1,
              WebkitBoxShadow: "0 0 0px 1000px #f3f4f6 inset",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    );
  };

  const renderMobileFilter = () => {
    return (
      <>
        <IconButton
          className="
          lg:!hidden"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          className="w-full"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>{hospitalFilter()}</MenuItem>
          <MenuItem>{referPointFilter()}</MenuItem>
          <MenuItem>{searchBar()}</MenuItem>
        </Menu>
      </>
    );
  };

  const renderLaptopFilter = () => {
    return (
      <Box
        className="gap-x-4 hidden
        lg:flex
        xl:w-3/6"
      >
        {hospitalFilter()}
        {referPointFilter()}
        {searchBar()}
      </Box>
    );
  };

  return (
    <AppBar id="refer-navbar" position="static" className="mt-16 !bg-white">
      <Toolbar variant="dense" className="py-4">
        <Box className="flex flex-grow items-center">
          <h5
            className="text-sky-500 mr-2
            md:mr-8 md:text-xl"
          >
            {referType === ReferralType.REFER_IN
              ? t("refer.referIn")
              : t("refer.referOut")}
          </h5>

          <Box
            className={`flex flex-${
              !superAdmin?.status ? "row" : "col gap-y-2"
            }`}
          >
            {superAdmin?.status && hospitalList.docs && (
              <Box className="mr-2 max-w-xs w-full">
                <Autocomplete
                  disableListWrap
                  loading={hospitalList.length <= 0}
                  ListboxComponent={ListboxComponent}
                  options={hospitalList.docs}
                  onChange={(option, value) => {
                    if (
                      value &&
                      Object.prototype.hasOwnProperty.call(value, "hospCode")
                    ) {
                      setSuperAdmin({
                        status: true,
                        selectedHospital: value,
                      });
                    }
                  }}
                  getOptionLabel={(option: {
                    hospCode: string;
                    hospName: string;
                  }) =>
                    `${option.hospCode} : ${shortHospName(option.hospName)}`
                  }
                  noOptionsText={"Can not find hospital code or hospital name"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder="เลือกโรงพยาบาล"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {hospitalList.length <= 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        style: { display: "flex", alignItems: "center" },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused":
                          {
                            marginTop: "-6px",
                          },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className="truncate cursor-pointer pl-2 hover:bg-slate-50"
                      key={option.hospCode}
                    >
                      {`${option.hospCode} : ${shortHospName(option.hospName)}`}
                    </li>
                  )}
                />
              </Box>
            )}

            <DatePicker
              views={["day"]}
              value={selectedDate}
              maxDate={dayjs()}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                if (!date) return;

                setFilters(null);
                setSearchText("");

                const isDateDifferent = !dayjs(calendarOpenFrom.current).isSame(
                  dayjs(date),
                  "day"
                );

                if (
                  !dateChangedByButton.current ||
                  (dateChangedByButton.current && isDateDifferent)
                ) {
                  setSelectedDate(date.toDate());
                }

                dateChangedByButton.current = false;
              }}
              onOpen={() => {
                const urlParams = new URLSearchParams(history.location.search);
                const dateParam = urlParams.get("date");
                calendarOpenFrom.current = dateParam;
              }}
              renderInput={(params) => (
                <TextField {...params} className="!rounded-full" />
              )}
              InputProps={{
                endAdornment: <CalendarTodayIcon className="text-gray-500" />,
              }}
              components={{
                LeftArrowButton: (props) => (
                  <Box>
                    <IconButton {...props} disabled={FetchingStatus}>
                      <KeyboardDoubleArrowLeft />
                    </IconButton>

                    <IconButton
                      {...props}
                      onClick={() => {
                        dateChangedByButton.current = true;
                        onPreviousDayChange();
                      }}
                      disabled={FetchingStatus}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Box>
                ),
                RightArrowButton: (props) => (
                  <Box>
                    <IconButton
                      {...props}
                      onClick={() => {
                        dateChangedByButton.current = true;
                        onNextDayChange();
                      }}
                      disabled={FetchingStatus}
                    >
                      <KeyboardArrowRight />
                    </IconButton>

                    <IconButton {...props} disabled={FetchingStatus}>
                      <KeyboardDoubleArrowRight />
                    </IconButton>
                  </Box>
                ),
              }}
              componentsProps={{
                leftArrowButton: { "aria-label": "Previous day" },
                rightArrowButton: { "aria-label": "Next day" },
              }}
            />
          </Box>

          <IconButton
            className="icon-button avatar-button"
            title="Refresh"
            onClick={handleFetchRefers}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        {renderMobileFilter()}
        {renderLaptopFilter()}

        <IconButton
          onClick={handleToggleViewMode}
          title={
            viewMode === "recent"
              ? t("refer.referMode.today")
              : t("refer.referMode.recent")
          }
          sx={{ ml: 2 }}
        >
          {viewMode === "recent" ? (
            <MoneyIcon fontSize="large" />
          ) : (
            <TodayIcon fontSize="large" />
          )}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
