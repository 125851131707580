import axios from "axios";
import { PENDING } from "../constants/referralStatus";
import { ReferralType } from "../types/refers";
import { getApiUrl } from "../functions/Commons";

export default async (dataRequest) => {
  try {
    const AuthStr = `Bearer ${dataRequest.idToken}`;
    const { startDate, endDate, fromHospCode, toHospCode, limit, cid, name } =
      dataRequest;

    let startDateSet = "";
    let endDateSet = "";
    if (startDate) {
      const startDateTime = new Date(`${startDate}`);
      startDateTime?.setHours(0);
      startDateSet = startDateTime?.toISOString();
    }

    if (endDate) {
      const endDateTime = new Date(`${endDate}`);
      endDateTime?.setHours(23, 59, 59);
      endDateSet = endDateTime?.toISOString();
    }

    const incomplete = dataRequest.incomplete || false;
    const type =
      dataRequest.type && dataRequest.type === ReferralType.REFER_BACK
        ? dataRequest.type
        : "";
    const filterDeathPatient = dataRequest.filterDeathPatient || false;

    let cidParam = "";
    if (cid !== "" && cid !== undefined && cid) {
      cidParam = `&cid=${cid}`;
    } else {
      cidParam = "";
    }

    let nameParam = "";
    if (name !== "" && name !== undefined && name) {
      nameParam = `&name=${name}`;
    } else {
      nameParam = "";
    }

    const url = `${getApiUrl()}/v1/refer?startDate=${startDateSet}&endDate=${endDateSet}&fromHospCode=${fromHospCode}&toHospCode=${toHospCode}&incomplete=${incomplete}&limit=${limit}&type=${type}&filterDeathPatient=${filterDeathPatient}${cidParam}${nameParam}`;

    const res = await axios.get(url, { headers: { Authorization: AuthStr } });
    const referData = res.data.result.referrals.map((item) =>
      item.status ? item : { ...item, status: PENDING }
    );

    const data = {
      emrData: {
        referData,
        referStartDate: startDateSet,
        referEndDate: endDateSet,
        referType: dataRequest.type,
      },
      FetchingStatus: false,
    };
    return data;
  } catch (error) {
    console.log("fetch refers error", error);
    return error;
  }
};
