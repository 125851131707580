import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

// styles
import "../../../../styles/refers/ReferRejectDialog.scss";
import cancelAppointment from "../../../../api/AppointmentOPD/cancelAppointment";

interface IReferCancelDialog {
  open: boolean;
  onClose(): void;
  token: string;
  referId: string;
}
function ReferCancelDialog({
  open,
  onClose,
  token,
  referId,
}: IReferCancelDialog) {
  const handleSubmit = async () => {
    try {
      await cancelAppointment(token, referId);
      onClose();
      Swal.fire("cancel appointment!", "", "success");
    } catch (error) {
      console.log(error);
      Swal.fire("cancel appointment!", "", "error");
    }
  };
  return (
    <Dialog
      fullWidth
      id="refer-reject-dialog"
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>
        <WarningIcon className="bg-red-50 p-1 rounded-full text-red-500 mr-2 !w-8 !h-8" />
        ยืนยันการยกเลิกนัดหมาย Refer
        <div className="float-right">
          <IconButton onClick={() => onClose()} size="medium">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogActions className="flex flex-col !px-5">
        <Button
          fullWidth
          size="small"
          className="!bg-red-500 !text-white
        hover:!bg-red-600"
          onClick={handleSubmit}
        >
          ยืนยันการยกเลิกนัดหมาย
        </Button>

        <Button
          fullWidth
          size="small"
          className="!mt-3 !text-sky-600
          hover:!bg-transparent"
          onClick={() => onClose()}
        >
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ReferCancelDialog;
