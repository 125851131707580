import React, { useEffect, useState } from "react";
import ReferNavbar from "./ReferNavbar";
import { IReferral, ReferralType } from "../../types/refers";
import ReferCard from "./ReferCard";
import { Box } from "@mui/material";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { useSelector, useDispatch } from "react-redux";
import { AnyObject } from "@casl/ability/dist/types/types";
import { RootState } from "../../reducers";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import acceptRefer from "../../actions/acceptRefer.action";
import rejectRefer from "../../actions/rejectRefer.action";
import Swal from "sweetalert2";

function ReferIn() {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { permissionRules, idToken } = appData;

  const [isRefresh, setIsRefresh] = useState(false);
  const [referList, setReferList] = useState<IReferral[]>([]);
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const handleAcceptRefer = (bid: string, appointmentInfo: object) => {
    if (!bid) return;

    dispatch(acceptRefer(idToken, bid, appointmentInfo));
    setIsRefresh(true);
  };

  const handleRejectRefer = (bid: string, reason: string) => {
    if (!reason)
      return Swal.fire({
        icon: "error",
        title: "กรุณากรอกข้อมูลเหตุผลการปฏิเสธ",
        confirmButtonText: "ตกลง",
      });

    if (bid) {
      dispatch(rejectRefer(idToken, bid, reason));
      setIsRefresh(true);
    }
  };

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  return (
    <>
      <ReferNavbar
        referType={ReferralType.REFER_IN}
        onUpdateReferList={(referList) => {
          setIsRefresh(false);
          setReferList(referList);
        }}
        isRefresh={isRefresh}
      />

      <Box
        sx={{
          display: "grid",
          gap: 2,
          p: 2,
          bgcolor: "grey.100",
          gridTemplateColumns: {
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
        }}
      >
        {referList.map((refer, index) => (
          <ReferCard
            key={refer.bid}
            avatarText={referList.length - index}
            referral={refer}
            canManageRefer={ability?.can(
              ServicePermissionEvent.UPDATE,
              ServicePermissionService.REFERRAL
            )}
            canEditReferStatus={ability?.can(
              ServicePermissionEvent.UPDATE,
              ServicePermissionService.EDIT_REFER_STATUS
            )}
            onAcceptRefer={(appointmentInfo) => {
              handleAcceptRefer(refer.bid, appointmentInfo);
            }}
            onRejectRefer={(reason) => {
              handleRejectRefer(refer.bid, reason);
            }}
          />
        ))}
      </Box>
    </>
  );
}

export default ReferIn;
