import axios from "axios";
import { getApiUrl } from "../functions/Commons";

export default (token: string, bid: string, appointmentInfo: object) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/refer/accept`;

  const body = {
    ...appointmentInfo,
    bid,
  };

  return axios
    .put(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      const dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
