import { APPDATA } from "../../constants/appData";
import fetchHospitalListForRefer from "../../api/Refers/fetchHospitalListForRefer.service";

interface IFetchHospitalList {
  idToken: string;
  page?: number;
  limit?: number;
  sortColumn?: string;
  sortType?: number;
  filterText?: string;
  filterList?: string[];
}

export default ({
  idToken,
  page,
  limit,
  sortColumn,
  sortType,
  filterText,
  filterList,
}: IFetchHospitalList) => ({
  type: APPDATA,
  payload: fetchHospitalListForRefer({
    idToken,
    page,
    limit,
    sortColumn,
    sortType,
    filterText,
    filterList,
  }),
});
